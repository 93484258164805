<template>
    <login-and-regist-layout :title="$t('signUp.title')" :loading="isLoading">
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
                <v-row>
                    <v-col md="10" class="mx-auto">
                        <v-text-field
                            autofocus
                            v-model="phoneNumber"
                            type="number"
                            :label="$t('signUp.phoneNumber')"
                            :placeholder="$t('signUp.phoneNumberPlaceholder')"
                            prepend-inner-icon="mdi-phone"
                            :rules="fieldRule(phoneNumberRule)"
                            persistent-placeholder
                            validate-on-blur
                        ></v-text-field>
                        <v-text-field
                            v-model="fullName"
                            :label="$t('signUp.fullName')"
                            :placeholder="
                                $t('formField.formFieldPlaceholder', {
                                    field: $t('signUp.fullName'),
                                })
                            "
                            prepend-inner-icon="mdi-account"
                            :rules="fieldRule(fullNameRule)"
                            validate-on-blur
                            persistent-placeholder
                        ></v-text-field>
                        <v-text-field
                            v-model="email"
                            :label="$t('signUp.email')"
                            :placeholder="
                                $t('formField.formFieldPlaceholder', {
                                    field: $t('signUp.email'),
                                })
                            "
                            prepend-inner-icon="mdi-account"
                            :rules="fieldRule(emailRule)"
                            persistent-placeholder
                            validate-on-blur
                        ></v-text-field>
                        <v-text-field
                            v-model="password"
                            :label="$t('signUp.password')"
                            :placeholder="
                                $t('formField.formFieldPlaceholder', {
                                    field: $t('signUp.password'),
                                })
                            "
                            type="password"
                            prepend-inner-icon="mdi-lock"
                            :rules="fieldRule(passwordRule)"
                            persistent-placeholder
                            autocomplete="new-password"
                            validate-on-blur
                        ></v-text-field>
                        <v-text-field
                            v-model="passwordConfirm"
                            :label="$t('signUp.confirmPassword')"
                            :placeholder="
                                $t('formField.formFieldPlaceholder', {
                                    field: $t('signUp.confirmPassword'),
                                })
                            "
                            type="password"
                            prepend-inner-icon="mdi-lock"
                            :rules="fieldRule(passwordConfirmRule)"
                            persistent-placeholder
                            autocomplete="new-password"
                            validate-on-blur
                        ></v-text-field>
                        <v-checkbox v-model="termOfUserConfirm" required>
                            <template v-slot:label>
                                <span class="text-caption">{{
                                    $t("signUp.termOfUser")
                                }}</span>
                            </template></v-checkbox
                        >
                        <v-btn
                            block
                            rounded
                            :disabled="!termOfUserConfirm || !isRegisable"
                            :loading="isLoading"
                            class="primary mt-5"
                            @click="onSignUp"
                        >
                            {{ $t("signUp.title") }}
                        </v-btn>
                        <p class="text-center mt-10 mb-3">
                            {{ $t("signUp.loginSuggest") }}
                        </p>
                        <router-link to="/login" class="text-decoration-none">
                            <p
                                class="text-center text-subtitle-1 text-uppercase"
                            >
                                {{ $t("login.title") }}
                            </p>
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        <otp-dialog
            :isShowDialog.sync="otpDialog"
            @onDialogConfirm="onDialogConfirm"
        ></otp-dialog>
        <div :id="recaptchaContainer" />
    </login-and-regist-layout>
</template>

<script>
import LoginAndRegistLayout from "@/layout/LoginAndRegistLayout.vue";
import commonFunc from "@/common/commonFunc.js";
import useVerifyOTP, { RECAPTCHA_CONTAINER } from "@/mixins/useVerifyOTP.js";
import screen from "@/mixins/screen.js";
import { mapActions, mapGetters } from "vuex";
import OTPDialog from "@/components/OTPDialog.vue";
import store from "@/store";

export default {
    components: {
        LoginAndRegistLayout,
        "otp-dialog": OTPDialog,
    },
    mixins: [useVerifyOTP, screen],
    data() {
        return {
            valid: true,
            phoneNumber: "",
            fullName: "",
            email: "",
            password: "",
            passwordConfirm: "",
            termOfUserConfirm: false,
            otpDialog: false,
            isRegisable: true,
            recaptchaContainer: RECAPTCHA_CONTAINER,
            phoneNumberRule: [
                (v) =>
                    !!v ||
                    this.$t("formField.formFieldRequired", {
                        field: this.$t("login.phoneNumber"),
                    }),
                (v) =>
                    /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/.test(v) ||
                    this.$t("formField.formFieldInvalid", {
                        field: this.$t("login.phoneNumber"),
                    }),
            ],
            passwordRule: [
                (v) =>
                    !!v ||
                    this.$t("formField.formFieldRequired", {
                        field: this.$t("signUp.password"),
                    }),
            ],
            passwordConfirmRule: [
                (v) =>
                    !!v ||
                    this.$t("formField.formFieldRequired", {
                        field: this.$t("signUp.confirmPassword"),
                    }),
                () =>
                    this.password === this.passwordConfirm ||
                    this.$t("signUp.passwordConfirmError"),
            ],
            fullNameRule: [
                (v) =>
                    !!v ||
                    this.$t("formField.formFieldRequired", {
                        field: this.$t("signUp.fullName"),
                    }),
            ],
            emailRule: [
                (v) =>
                    !!v ||
                    this.$t("formField.formFieldRequired", {
                        field: this.$t("signUp.email"),
                    }),
                (v) =>
                    /.+@.+\..+/.test(v) ||
                    this.$t("formField.formFieldInvalid", {
                        field: this.$t("signUp.email"),
                    }),
            ],
        };
    },
    beforeRouteEnter(from, to, next) {
        store.dispatch("auth/resetPhoneStatus");
        next();
    },
    computed: {
        ...mapGetters({
            isPhoneAvailable: "auth/isPhoneAvailable",
            isAuthenticated: "auth/isAuthenticated",
        }),
    },
    watch: {
        isAuthenticated(newVal) {
            if (newVal) {
                this.$router.push("/marketing"); 
            }
        },
        async isPhoneAvailable(newVal) {
            if (newVal === true) {
                this.isRegisable = false;
                await this.sendOTP(commonFunc.formatPhone(this.phoneNumber))
                    .then(() => {
                        this.otpDialog = true;
                    })
                    .catch((error) => {
                        this.setError(error);
                        this.isRegisable = true;
                    });
            } else if (newVal === false) {
                this.setError(this.$t("signUp.alreadyRegisteredPhone"));
            }
        },
        otpDialog(newVal) {
            if (newVal === false) {
                this.isRegisable = true;
            }
        },
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },
        reset() {
            this.$refs.form.reset();
        },
        async onSignUp() {
            this.validate();
            if (this.valid) {
                this.checkPhoneNumber(
                    commonFunc.toPhoneNumber(this.phoneNumber)
                );
            }
        },
        async onDialogConfirm(otpCode) {
            try {
                const token = await this.confirmOTP(otpCode);
                this.otpDialog = false;
                const payload = Object.freeze({
                    username: commonFunc.formatPhone(this.phoneNumber),
                    fullName: this.fullName,
                    email: this.email,
                    password: this.password,
                    tos: this.termOfUserConfirm,
                    idToken: token,
                });
                this.customerRegister(payload);
            } catch (error) {
                this.setError(error);
            }
        },
        ...mapActions({
            checkPhoneNumber: "auth/checkPhoneNumber",
            customerRegister: "auth/customerRegister",
        }),
    },
};
</script>
