var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('login-and-regist-layout',{attrs:{"title":_vm.$t('signUp.title'),"loading":_vm.isLoading}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"md":"10"}},[_c('v-text-field',{attrs:{"autofocus":"","type":"number","label":_vm.$t('signUp.phoneNumber'),"placeholder":_vm.$t('signUp.phoneNumberPlaceholder'),"prepend-inner-icon":"mdi-phone","rules":_vm.fieldRule(_vm.phoneNumberRule),"persistent-placeholder":"","validate-on-blur":""},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('v-text-field',{attrs:{"label":_vm.$t('signUp.fullName'),"placeholder":_vm.$t('formField.formFieldPlaceholder', {
                                field: _vm.$t('signUp.fullName'),
                            }),"prepend-inner-icon":"mdi-account","rules":_vm.fieldRule(_vm.fullNameRule),"validate-on-blur":"","persistent-placeholder":""},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}}),_c('v-text-field',{attrs:{"label":_vm.$t('signUp.email'),"placeholder":_vm.$t('formField.formFieldPlaceholder', {
                                field: _vm.$t('signUp.email'),
                            }),"prepend-inner-icon":"mdi-account","rules":_vm.fieldRule(_vm.emailRule),"persistent-placeholder":"","validate-on-blur":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"label":_vm.$t('signUp.password'),"placeholder":_vm.$t('formField.formFieldPlaceholder', {
                                field: _vm.$t('signUp.password'),
                            }),"type":"password","prepend-inner-icon":"mdi-lock","rules":_vm.fieldRule(_vm.passwordRule),"persistent-placeholder":"","autocomplete":"new-password","validate-on-blur":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"label":_vm.$t('signUp.confirmPassword'),"placeholder":_vm.$t('formField.formFieldPlaceholder', {
                                field: _vm.$t('signUp.confirmPassword'),
                            }),"type":"password","prepend-inner-icon":"mdi-lock","rules":_vm.fieldRule(_vm.passwordConfirmRule),"persistent-placeholder":"","autocomplete":"new-password","validate-on-blur":""},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}}),_c('v-checkbox',{attrs:{"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t("signUp.termOfUser")))])]},proxy:true}]),model:{value:(_vm.termOfUserConfirm),callback:function ($$v) {_vm.termOfUserConfirm=$$v},expression:"termOfUserConfirm"}}),_c('v-btn',{staticClass:"primary mt-5",attrs:{"block":"","rounded":"","disabled":!_vm.termOfUserConfirm || !_vm.isRegisable,"loading":_vm.isLoading},on:{"click":_vm.onSignUp}},[_vm._v(" "+_vm._s(_vm.$t("signUp.title"))+" ")]),_c('p',{staticClass:"text-center mt-10 mb-3"},[_vm._v(" "+_vm._s(_vm.$t("signUp.loginSuggest"))+" ")]),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/login"}},[_c('p',{staticClass:"text-center text-subtitle-1 text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("login.title"))+" ")])])],1)],1)],1)],1),_c('otp-dialog',{attrs:{"isShowDialog":_vm.otpDialog},on:{"update:isShowDialog":function($event){_vm.otpDialog=$event},"update:is-show-dialog":function($event){_vm.otpDialog=$event},"onDialogConfirm":_vm.onDialogConfirm}}),_c('div',{attrs:{"id":_vm.recaptchaContainer}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }